import React, { useState } from "react"
import ThemeContext from "../context/ThemeContext"
import { Link } from "gatsby"
import styled from "@emotion/styled"
import logo from "../../static/logo/logo-solarair.png"
import ButtonWrapper from "./Button"
import options from "../../config/options"
import { Row, Menu, Dropdown, Button, Popover } from "antd"
import { DownOutlined, MenuOutlined, CloseOutlined } from "@ant-design/icons"

const { SubMenu } = Menu
const HeaderWrapper = styled.header`
  width: 100%;
  padding: 20px 0;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  transition: visibility 0.05s ease-in-out;
  background-color: transparent;
  .siteLogo {
    max-height: 60px;
    max-width: 159px;
    img {
      max-height: 60px;
      max-width: 159px;
    }
  }
  #site__navbar {
    list-style: none;
    line-height: 38px;
    border-bottom: none;
    background: transparent;
    display: none;
    align-items: center;
    margin-bottom: 0;
    padding: 0;
  }
  .ctaWrapper {
    margin-left: 20px;
  }
  .mobileMenuWrapper {
    display: block;
    button {
      font-size: 18px;
      color: #fff;
      background: #1482c7;
      border-color: #1482c7;
      width: 38px;
      height: 32px;
      svg {
        color: #fff;
        fill: #fff;
        width: 32px;
        height: 20px;
      }
    }
  }
  @media only screen and (min-width: 576px) {
    .siteLogo {
      max-height: 65px;
      max-width: 173px;
      img {
        max-height: 65px;
        max-width: 173px;
      }
    }
  }
  @media only screen and (min-width: 992px) {
    .siteLogo {
      max-height: 75px;
      max-width: 199px;
      img {
        max-height: 75px;
        max-width: 199px;
      }
    }
  }
  @media only screen and (min-width: 1200px) {
    #site__navbar {
      display: flex;
    }
    .mobileMenuWrapper {
      display: none;
    }
  }
`
const LiWrapper = styled.li`
  display: inline-flex;
  align-items: center;
  border-bottom: none !important;
  max-height: 38px;
  padding: 0 10px;
  white-space: nowrap;
  a {
    font-family: "Prompt", sans-serif;
    font-weight: 400;
    font-size: 15px;
    color: #fff;
    &:hover {
      font-weight: 500;
      color: #0699e2;
    }
  }
  .active {
    font-weight: 500;
    color: #0699e2;
  }
`

const HeaderTransParent = () => {
  const [popVisible, setPopVisible] = useState(false)

  const subMenu1 = (
    <Menu className="subMenuWrapper">
      <Menu.Item key="1">
        <Link to="/solarair1">แอร์ 2ระบบ 13,000btu รุ่นประหยัด 2แผง</Link>
        <Link to="/solarair2">แอร์ 2ระบบ 13,000btu รุ่นท็อปสุด 3แผง</Link>
        <Link to="/solarair3">แอร์ 2ระบบ 19,000btu รุ่นประหยัด 4แผง</Link>
        <Link to="/solarair4">แอร์ 2ระบบ 19,000btu รุ่นท็อปสุด 5แผง</Link>
        <Link to="/solarair5">แอร์ 2ระบบ 25,000btu รุ่นประหยัด 5แผง</Link>
        <Link to="/solarair6">แอร์ 2ระบบ 25,000btu รุ่นท็อปสุด 6แผง</Link>
      </Menu.Item>
    </Menu>
  )
  const subMenu2 = (
    <Menu className="subMenuWrapper">
      <Menu.Item key="2">
        <Link to="/promotion1">โปรโมชั่น แจกทองฟรี!</Link>
        <Link to="/promotion2">โปรโมชั่น ส่งฟรี! ถึงบ้านทั่วไทย</Link>
      </Menu.Item>
    </Menu>
  )
  const subMenu3 = (
    <Menu className="subMenuWrapper">
      <Menu.Item key="3">
        <Link to="/energy-saving">อัตราประหยัดไฟ แอร์เก่าvsแอร์โซล่าเซลล์</Link>
      </Menu.Item>
    </Menu>
  )
  const subMenu4 = (
    <Menu className="subMenuWrapper">
      <Menu.Item key="4">
        <Link to="/install-air1">1.วิธีติดตั้งแอร์ ใช้ไฟบ้านอย่างเดียว</Link>
        <Link to="/install-air2">
          2.วิธีติดตั้งแอร์ ใช้ไฟบ้าน และโซล่าเซลล์
        </Link>
      </Menu.Item>
    </Menu>
  )
  const subMenu5 = (
    <Menu className="subMenuWrapper">
      <Menu.Item key="5">
        <Link to="/contact-us">สอบถาม-สั่งซื้อ</Link>
        <Link to="/company-profile">ประวัติบริษัท และโรงงาน</Link>
      </Menu.Item>
    </Menu>
  )
  const mobileMenu = (
    <Menu mode="inline">
      <Menu.Item key="home">
        <Link to="/">หน้าแรก</Link>
      </Menu.Item>
      <SubMenu key="sub1" title="แอร์โซล่าเซลล์">
        <Menu.Item key="11">
          <Link to="/solarair1">แอร์ 2ระบบ 13,000btu รุ่นประหยัด 2แผง</Link>
        </Menu.Item>
        <Menu.Item key="12">
          <Link to="/solarair2">แอร์ 2ระบบ 13,000btu รุ่นท็อปสุด 3แผง</Link>
        </Menu.Item>
        <Menu.Item key="13">
          <Link to="/solarair3">แอร์ 2ระบบ 19,000btu รุ่นประหยัด 4แผง</Link>
        </Menu.Item>
        <Menu.Item key="14">
          <Link to="/solarair4">แอร์ 2ระบบ 19,000btu รุ่นท็อปสุด 5แผง</Link>
        </Menu.Item>
        <Menu.Item key="15">
          <Link to="/solarair5">แอร์ 2ระบบ 25,000btu รุ่นประหยัด 5แผง</Link>
        </Menu.Item>
        <Menu.Item key="16">
          <Link to="/solarair6">แอร์ 2ระบบ 25,000btu รุ่นท็อปสุด 6แผง</Link>
        </Menu.Item>
      </SubMenu>
      <SubMenu key="sub2" title="โปรโมชั่นแอร์">
        <Menu.Item key="21">
          <Link to="/promotion1">โปรโมชั่น แจกทองฟรี!</Link>
        </Menu.Item>
        <Menu.Item key="22">
          <Link to="/promotion2">โปรโมชั่น ส่งฟรี! ถึงบ้านทั่วไทย</Link>
        </Menu.Item>
      </SubMenu>
      <SubMenu key="sub3" title="วิธีคำนวณค่าไฟ">
        <Menu.Item key="31">
          <Link to="/energy-saving">
            อัตราประหยัดไฟ แอร์เก่าvsแอร์โซล่าเซลล์
          </Link>
        </Menu.Item>
      </SubMenu>
      <SubMenu key="sub4" title="วิธีติดตั้งแอร์">
        <Menu.Item key="41">
          <Link to="/install-air1">1.วิธีติดตั้งแอร์ ใช้ไฟบ้านอย่างเดียว</Link>
        </Menu.Item>
        <Menu.Item key="42">
          <Link to="/install-air2">
            2.วิธีติดตั้งแอร์ ใช้ไฟบ้าน และโซล่าเซลล์
          </Link>
        </Menu.Item>
      </SubMenu>
      <SubMenu key="sub5" title="ติดต่อเรา">
        <Menu.Item key="51">
          <Link to="/contact-us">สอบถาม-สั่งซื้อ</Link>
        </Menu.Item>
        <Menu.Item key="52">
          <Link to="/company-profile">ประวัติบริษัท และโรงงาน</Link>
        </Menu.Item>
      </SubMenu>
      <div
        style={{
          height: "44px",
          lineHeight: "44px",
          paddingLeft: "30px",
          marginTop: "25px",
        }}
      >
        <ButtonWrapper
          text={options.telText}
          cta={options.telCTA}
          path="target"
          icon="phone"
        />
      </div>
    </Menu>
  )

  const handleVisibleChange = visible => {
    setPopVisible(visible)
  }

  return (
    <ThemeContext.Consumer>
      {() => (
        <div id="site__header__wrapper">
          <HeaderWrapper
            id="site__header"
            style={{ backgroundColor: popVisible ? `#075181` : `#075181` }}
          >
            <div className="gb-container">
              <Row justify="space-between" align="middle">
                <Link to="/" className="siteLogo" aria-label="AEC SOLARAIR">
                  <img src={logo} width="201" height="75" alt="AEC SOLARAIR" />
                </Link>
                <ul id="site__navbar">
                  <LiWrapper key="home">
                    <Link activeClassName="active" to="/">
                      หน้าแรก
                    </Link>
                  </LiWrapper>
                  <LiWrapper key="subMenu1">
                    <Dropdown overlay={subMenu1}>
                      <a
                        href="#void"
                        className="ant-dropdown-link"
                        onClick={e => e.preventDefault()}
                      >
                        แอร์โซล่าเซลล์ <DownOutlined />
                      </a>
                    </Dropdown>
                  </LiWrapper>
                  <LiWrapper key="subMenu2">
                    <Dropdown overlay={subMenu2}>
                      <a
                        href="#void"
                        className="ant-dropdown-link"
                        onClick={e => e.preventDefault()}
                      >
                        โปรโมชั่นแอร์ <DownOutlined />
                      </a>
                    </Dropdown>
                  </LiWrapper>
                  <LiWrapper key="subMenu3">
                    <Dropdown overlay={subMenu3}>
                      <a
                        href="#void"
                        className="ant-dropdown-link"
                        onClick={e => e.preventDefault()}
                      >
                        วิธีคำนวณค่าไฟ <DownOutlined />
                      </a>
                    </Dropdown>
                  </LiWrapper>
                  <LiWrapper key="subMenu4">
                    <Dropdown overlay={subMenu4}>
                      <a
                        href="#void"
                        className="ant-dropdown-link"
                        onClick={e => e.preventDefault()}
                      >
                        วิธีติดตั้งแอร์ <DownOutlined />
                      </a>
                    </Dropdown>
                  </LiWrapper>
                  <LiWrapper key="subMenu5">
                    <Dropdown overlay={subMenu5}>
                      <a
                        href="#void"
                        className="ant-dropdown-link"
                        onClick={e => e.preventDefault()}
                      >
                        ติดต่อเรา <DownOutlined />
                      </a>
                    </Dropdown>
                  </LiWrapper>
                  <li key="cta" className="ctaWrapper">
                    <ButtonWrapper
                      text={options.telText}
                      cta={options.telCTA}
                      path="target"
                      icon="phone"
                    />
                  </li>
                </ul>
                <div className="mobileMenuWrapper">
                  <Popover
                    placement="bottomRight"
                    content={mobileMenu}
                    trigger="click"
                    visible={popVisible}
                    onVisibleChange={handleVisibleChange}
                  >
                    <Button
                      type="primary"
                      icon={popVisible ? <CloseOutlined /> : <MenuOutlined />}
                      onClick={handleVisibleChange}
                    />
                  </Popover>
                </div>
              </Row>
            </div>
          </HeaderWrapper>
        </div>
      )}
    </ThemeContext.Consumer>
  )
}

export default HeaderTransParent
